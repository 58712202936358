import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import * as API from "../../services/env";

const KEYS = {
  comRegCert: "comRegCert",
  comAddress: "comAddress",
  ownerID: "ownerID",
  ownerAddress: "ownerAddress",
};

const RequestVerificationDocs = () => {
  const [fileNames, setFileNames] = useState({
    comRegCert: null,
    comAddress: null,
    ownerID: null,
    ownerAddress: null,
  });

  const handleFileChange = (event, key) => {
    const file = event.target.files[0];
    console.log(file.name, key);
    // setFileNames({ ...fileNames, [key]: file.name });
    // handleUpload(file);
  };

  const handleUpload = async (file) => {
    let fileToUpload = new FormData();
    fileToUpload.append("attachments", file);

    var requestOptions = {
      method: "POST",
      body: fileToUpload,
    };
    await fetch(`${API.BASE_URL}uploadHsCodeSearchExcelFile`, requestOptions)
      .then((response) => response.json())
      .then(async (res) => {
        console.log(res);
        if (res.success && Array.isArray(res.data)) {
        }
      });
  };

  return (
    <>
      <Row className="mb-3">
        <Col md={6}>
          <div>
            <div className="uploadBox">
              <div className="file">
                <label htmlFor="qualificationUpload">
                  <h3>Company Registration Certificate</h3>
                  <p>+Upload Documents Here</p>
                  {fileNames.comRegCert && (
                    <span style={{ color: "#056aec", fontWeight: "bold" }}>
                      {fileNames.comRegCert}
                    </span>
                  )}
                </label>
                <input
                  id="qualificationUpload"
                  type="file"
                  name="qualification"
                  accept="application/pdf"
                  onChange={(e) => {
                    handleFileChange(e, KEYS.comAddress);
                  }}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="uploadBox">
            <div className="file">
              <label htmlFor="qualificationUpload">
                <h3>Proof of Company Address</h3>
                <p>+Upload Documents Here</p>
                {fileNames.comAddress && (
                  <span style={{ color: "#056aec", fontWeight: "bold" }}>
                    {fileNames.comAddress}
                  </span>
                )}
              </label>
              <input
                id="qualificationUpload"
                type="file"
                name="qualification"
                accept="application/pdf"
                onChange={(e) => {
                  handleFileChange(e);
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div>
            <div className="uploadBox">
              <div className="file">
                <label htmlFor="qualificationUpload">
                  <h3>Owner’s Drivers Licence: or ID : or Passport</h3>
                  <p>+Upload Documents Here</p>
                  {fileNames.ownerID && (
                    <span style={{ color: "#056aec", fontWeight: "bold" }}>
                      {fileNames.ownerID}
                    </span>
                  )}
                </label>
                <input
                  id="qualificationUpload"
                  type="file"
                  name="qualification"
                  accept="application/pdf"
                  onChange={(e) => {
                    handleFileChange(e);
                  }}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className="uploadBox">
            <div className="file">
              <label htmlFor="qualificationUpload">
                <h3>Proof of Owners Address </h3>
                <p>+Upload Documents Here</p>
                {fileNames.ownerAddress && (
                  <span style={{ color: "#056aec", fontWeight: "bold" }}>
                    {fileNames.ownerAddress}
                  </span>
                )}
              </label>
              <input
                id="qualificationUpload"
                type="file"
                name="qualification"
                accept="application/pdf"
                onChange={(e) => {
                  handleFileChange(e);
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RequestVerificationDocs;
